body{
    height: 100vh;
    *{padding:0;margin:0;}
}
.startup {
    background-image: url(./background.jpg);
    position: fixed; top: 0; left:0;
    width: 100%;
    height:100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .startBtn{
        height:50px;
        width: 50%;
        position: absolute;
        bottom: 75px;
        left: 50%;
        transform: translateX(-50%);
        background-image: url("./background-btn.jpg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;

        line-height: 50px;
        text-align: center;
        color:#A03A09;
        font-weight: bold;
    }
    .activeBtn{
        opacity: 1;
    }
}

.hide{display: none}

